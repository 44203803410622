import { client as apolloClient } from '~/api/graphql/client';
import { getAuthorizationHeader } from '~/utils/auth';

import { GET_USER_INFO } from './queries';

import type { Request } from 'express';
import type { AppContext } from 'next/app';
import type { UserDetails } from '~/common/types/user';

export const getUserInfo = async (
  context: AppContext
): Promise<{ numberOfUnreadMessages: number | null; userDetails: UserDetails | null }> => {
  const { req: request } = context.ctx;

  const { data } = await apolloClient.query({
    query: GET_USER_INFO,
    fetchPolicy: 'no-cache',
    context: {
      headers: {
        ...getAuthorizationHeader(request as Request),
      },
    },
  });

  return data;
};
