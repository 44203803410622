import { SplitFactory } from '@splitsoftware/splitio-browserjs';
import { useCallback, useEffect, useRef, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';

import { HOME_PAGE_SLUG } from '~/common/constants/pageLayout';

import {
  HOUSE_VISITS_HIGHLIGHTING_SPLIT_ID,
  AREA10_CMAX_SEO_CONTENT_ON_SEARCH_FOR_SITTER_PAGE_SPLIT_ID,
  AREA10_CMAX_SEO_CONTENT_ON_SITTER_PROFILE_PAGE_SPLIT_ID,
  PRICE_CONSISTENCY_SPLIT_ID,
  SPLIT_IO_INTERVAL_TO_INIT,
  TWO_FACTOR_AUTHENTICATION_SOCIAL_LOGINS_ID,
  FEATURE_HOLD_OUT_SPLIT_ID,
  HOME_PAGE_CONVERSION_TEST_SPLIT_ID,
} from './constants';
import { SplitIOContext } from './context';
import { getSegmentAnonymousId } from '../analytics/analytics';

import type SplitIO from '@splitsoftware/splitio-browserjs/types/splitio';
import type { Request } from 'express';
import type { NextPageContext } from 'next';
import type { AppContext } from 'next/app';
import type { ReactElement, ReactNode } from 'react';
import type { UserDetails } from '~/api/graphql/user/typeDefs';

const NEXT_PUBLIC_SPLITIO_AUTH_KEY = process.env.NEXT_PUBLIC_SPLITIO_AUTH_KEY;

type Props = {
  children: ReactNode;
  ssrTreatments?: SplitIO.Treatments | null;
  userId: number | undefined | null;
  userPostCode: string | undefined | null;
};

export const initSplitIO = async (
  key: string,
  featureFlags: string[],
  attributes?: SplitIO.Attributes
): Promise<{ client: SplitIO.IClient; treatments: SplitIO.Treatments }> => {
  const factory: SplitIO.ISDK = SplitFactory({
    core: {
      authorizationKey: NEXT_PUBLIC_SPLITIO_AUTH_KEY || '',
      key,
    },
  });

  const client = factory.client();

  // Wait for the SDK to be ready before fetching the treatment
  await new Promise((resolve) => client.on(client.Event.SDK_READY, resolve));

  const treatments = client.getTreatments(featureFlags, attributes);

  return { client, treatments };
};

/**
 * For now this will be called only on the home page
 * Disabled splitIO during lighhouse testing cause of a timeout issue
 */
export const InitiateSSRSplitIO = async (
  appContext: AppContext & NextPageContext,
  userDetails: UserDetails | null
): Promise<SplitIO.Treatments | null> => {
  if (appContext.ctx.pathname !== HOME_PAGE_SLUG || process.env.LHCI_TESTING) {
    return null;
  }

  const request = appContext.ctx.req as Request;
  const anonymousId = request?.cookies?.ajs_anonymous_id;
  const key = userDetails?.id.toString() || anonymousId || uuidv4();

  const { client: splitIOClient, treatments } = await initSplitIO(
    key,
    [HOME_PAGE_CONVERSION_TEST_SPLIT_ID],
    { postcode: userDetails?.postcode ?? '' }
  );

  splitIOClient.destroy();

  return treatments;
};

export const SplitIOProvider = ({
  children,
  ssrTreatments,
  userId,
  userPostCode,
}: Props): ReactElement => {
  const splitInitialised = useRef(false);
  const [treatments, setTreatments] = useState<SplitIO.Treatments>(ssrTreatments ?? {});
  const [segmentAnonymousId, setSegmentAnonymousId] = useState<string | undefined>();
  const [isSplitIoLoading, setIsSplitIoLoading] = useState<boolean>(true);

  const initAndSetTreatments = useCallback(
    async (splitIOKey: string): Promise<void> => {
      if (splitInitialised.current) {
        return;
      }

      const { treatments: splitIOTreatments } = await initSplitIO(
        splitIOKey,
        [
          HOUSE_VISITS_HIGHLIGHTING_SPLIT_ID,
          AREA10_CMAX_SEO_CONTENT_ON_SEARCH_FOR_SITTER_PAGE_SPLIT_ID,
          AREA10_CMAX_SEO_CONTENT_ON_SITTER_PROFILE_PAGE_SPLIT_ID,
          PRICE_CONSISTENCY_SPLIT_ID,
          TWO_FACTOR_AUTHENTICATION_SOCIAL_LOGINS_ID,
          FEATURE_HOLD_OUT_SPLIT_ID,
          HOME_PAGE_CONVERSION_TEST_SPLIT_ID,
        ],
        { postcode: userPostCode ?? '' }
      );

      setTreatments(splitIOTreatments);
      setIsSplitIoLoading(false);
      splitInitialised.current = true;
    },
    [userPostCode, setTreatments, setIsSplitIoLoading, splitInitialised]
  );

  useEffect(() => {
    if (!window.analytics || !NEXT_PUBLIC_SPLITIO_AUTH_KEY) {
      return;
    }

    // To init SplitIO we use AnonymousId by Segment analytics
    // Current approach checks analytics object for it
    // with 1 sec interval and clears interval on success
    const interval = setInterval(() => {
      const segmentId = getSegmentAnonymousId();

      if (segmentId) {
        setSegmentAnonymousId(segmentId);
        clearInterval(interval);
      }
    }, SPLIT_IO_INTERVAL_TO_INIT);
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    if (userId) {
      initAndSetTreatments(`${userId}`);
    } else if (segmentAnonymousId) {
      initAndSetTreatments(segmentAnonymousId);
    }
  }, [segmentAnonymousId, initAndSetTreatments, userId]);

  const getTreatments = async (): Promise<SplitIO.Treatments> =>
    new Promise((resolve) => {
      if (!isSplitIoLoading) {
        resolve(treatments);
      } else {
        const checkInterval = setInterval(() => {
          if (!isSplitIoLoading) {
            clearInterval(checkInterval);
            resolve(treatments);
          }
        }, 100);
      }
    });

  return (
    <SplitIOContext.Provider value={{ getTreatments, isSplitIoLoading, treatments }}>
      {children}
    </SplitIOContext.Provider>
  );
};
