type UtmParameters = {
  utm_campaign_lt: string;
  utm_content_lt: string;
  utm_medium_lt: string;
  utm_source_lt: string;
};

export const getUtmParameters = (): UtmParameters => {
  const queryStringParams = new URLSearchParams(window.location.search);

  return {
    utm_campaign_lt: queryStringParams.get('utm_campaign_lt') ?? '',
    utm_content_lt: queryStringParams.get('utm_content_lt') ?? '',
    utm_medium_lt: queryStringParams.get('utm_medium_lt') ?? '',
    utm_source_lt: queryStringParams.get('utm_source_lt') ?? '',
  };
};

export const trackPage = (): void => {
  window?.analytics?.page();
};

export const getSegmentAnonymousId = (): string => window?.analytics?.user?.()?.anonymousId();

export const trackEvent = (
  eventName: string,
  properties?: Record<string, unknown>,
  includeUtmParameters = true
): void => {
  const trackProperties = {
    ...properties,
    user_channel: 'website',
  };

  if (includeUtmParameters) {
    Object.assign(trackProperties, getUtmParameters());
  }

  window?.analytics?.track(eventName, trackProperties);
};

export const identify = (userId: number, traits: Record<string, unknown>): void => {
  window?.analytics?.identify(userId, traits);
};
