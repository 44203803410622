import React, { useContext, createContext, useState } from 'react';

import { AuthDialog } from './AuthDialog/AuthDialog';

import type { ReactElement, ReactNode } from 'react';

type Props = {
  children: ReactNode;
  route: string;
};

export type Context = {
  isDialogOpen: boolean;
  openAuthDialog: () => void;
};

export const AuthenticationDialogContext = createContext<Context>({
  isDialogOpen: false,
  openAuthDialog: () => {},
});

export const useAuthenticationDialogContext = (): Context =>
  useContext(AuthenticationDialogContext);

export const AuthenticationProvider = ({ children }: Props): ReactElement => {
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const openAuthDialog = (): void => {
    setIsDialogOpen(true);
  };

  const closeAuthDialog = (): void => {
    setIsDialogOpen(false);
  };

  return (
    <AuthenticationDialogContext.Provider
      value={{
        isDialogOpen: isDialogOpen,
        openAuthDialog,
      }}
    >
      <>
        {children}
        <AuthDialog handleDialogClose={closeAuthDialog} isOpen={isDialogOpen} />
      </>
    </AuthenticationDialogContext.Provider>
  );
};
