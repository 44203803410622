import { gql } from '@apollo/client';

// NOTE: id field is also requested just so Apollo Client is able
// to cache the results from the userDetails query and no additional
// request is necessary on re-renders. For more info, refer to
// https://www.apollographql.com/docs/react/caching/cache-configuration/#customizing-cache-ids.
// NOTE: exported for testing purposes only
export const GET_USER_INFO = gql`
  query userInfo {
    userDetails {
      avatar
      city
      fullName
      name
      id
      isSitter
      rewriteUrl
      state
      postcode
    }
    numberOfUnreadMessages
  }
`;
