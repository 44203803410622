import { Button, LayoutGridContainer, TextField } from '@madpaws/design-system';
import { Formik } from 'formik';
import { useRouter } from 'next/router';
import React, { useState } from 'react';

import { SIGN_IN_ROUTE } from '~/common/constants/auth';
import { apiService } from '~/utils/apiService';

import styles from './AuthDialog.module.css';
import { emailValidationSchema } from './utils';

import type { ChangeEvent, ReactElement } from 'react';

type EmailSignUpFormValues = {
  email: string;
};

export const EmailSignUpForm = (): ReactElement => {
  const [formError, setFormError] = useState<string | null>(null);
  const router = useRouter();
  const formInitialValues = { email: '' };

  const onSubmitForm = async (values: EmailSignUpFormValues): Promise<void> => {
    const requestBody = new FormData();
    requestBody.append('email', values.email);

    try {
      const response = await apiService({
        body: requestBody,
        url: '/v3/users/validate_email',
      });
      const responseJson = await response.json();

      router.push(
        `${SIGN_IN_ROUTE}?redirectUrl=${router.asPath}&email=${values.email}&step=${
          responseJson.isTaken ? 'login' : 'sign-up'
        }`
      );
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <Formik
      initialValues={formInitialValues}
      onSubmit={onSubmitForm}
      validationSchema={emailValidationSchema}
    >
      {({ values, isSubmitting, isValid, setFieldValue, submitForm }): ReactElement => {
        const isSubmitButtonDisabled = isSubmitting || !isValid;

        const onTextFieldChange = (event: ChangeEvent<HTMLInputElement>): void => {
          const {
            target: { name, value },
          } = event;

          setFieldValue(name, value);
          // clear form error when user starts typing
          setFormError(null);
        };

        return (
          <div className={styles.form}>
            <LayoutGridContainer rowGap="default">
              <TextField
                isLabelVisuallyHidden
                label="Email"
                name="email"
                onChange={onTextFieldChange}
                value={values.email}
              />
            </LayoutGridContainer>
            <p className={styles.error}>{formError}</p>

            <div className={styles.buttonContainer}>
              <Button
                isDisabled={isSubmitButtonDisabled}
                isFullBleed
                label="Continue"
                onClick={submitForm}
                variant="secondary"
              />
            </div>
          </div>
        );
      }}
    </Formik>
  );
};
