import * as Sentry from '@sentry/nextjs';

import { getAuthorizationHeaderFromCookie } from './auth';

type ApiServiceInput = {
  body?: BodyInit;
  method?: string;
  url: string;
};

// TODO (WEB-674): Refactor API service for direct requests to API Woof
export const apiService = async ({
  method = 'POST',
  body,
  url,
}: ApiServiceInput): Promise<Response> => {
  try {
    const baseURL = process.env.NEXT_PUBLIC_API_BASE_URL;

    return await fetch(`${baseURL}${url}`, {
      method,
      body,
      headers: new Headers(getAuthorizationHeaderFromCookie()),
    });
  } catch (error) {
    Sentry.captureException(error);
    throw error;
  }
};
